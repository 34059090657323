export default [
  {
    title: 'Anasayfa',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Müşteri Kartları',
    route: 'Customers',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'CustomerAdd',
        resource: 'ADMIN_CUSTOMER_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Customers',
        resource: 'ADMIN_CUSTOMER_LIST',
        action: 'read',
      },

    ],
  },
  {
    title: 'Görüşmeler',
    route: 'Interviews',
    icon: 'MessageCircleIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'InterviewCustomerSearch',
        resource: 'ADMIN_INTERVIEW_ADD',
        action: 'read',
      },
      {
        title: 'Görüşme Listesi',
        route: 'Interviews',
        resource: 'ADMIN_INTERVIEW_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Hatırlatmalar',
    route: 'Reminders',
    icon: 'BellIcon',
    children: [
      {
        title: 'Hatırlatmalarım',
        route: 'Reminders',
        resource: 'ADMIN_REMINDER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Teklifler',
    route: 'Offers',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'OfferCustomerSearch',
        resource: 'ADMIN_OFFER_ADD',
        action: 'read',
      },
      {
        title: 'Açık Teklifler',
        route: 'OpenOffers',
        resource: 'ADMIN_OFFER_LIST',
        action: 'read',
      },
      {
        title: 'Tüm Teklifler',
        route: 'Offers',
        resource: 'ADMIN_OFFER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Proforma',
    route: 'ProformaInvoices',
    icon: 'FileIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'ProformaCustomerSearch',
        resource: 'ADMIN_PROFORMA_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'ProformaInvoices',
        resource: 'ADMIN_PROFORMA_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Servis Formları',
    route: 'Services',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'ServiceCustomerSearch',
        resource: 'ADMIN_SERVICE_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Services',
        resource: 'ADMIN_SERVICE_LIST',
        action: 'read',
      },
    ],
  },
  {
    header: 'Yönetim',
    resource: 'ADMIN',
    action: 'read',
  },
  {
    title: 'Raporlar',
    route: '',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'Görüşmeler',
        route: 'ReportInterviews',
        resource: 'ADMIN_REPORTS_INTERVIEW',
        action: 'read',
      },
      {
        title: 'Teklifler',
        route: 'ReportOffers',
        resource: 'ADMIN_REPORTS_OFFER',
        action: 'read',
      },
      {
        title: 'Servis',
        route: 'ReportServices',
        resource: 'ADMIN_REPORTS_SERVICE',
        action: 'read',
      },
      {
        title: 'Servis Muhasebe',
        route: 'ReportServiceAccounting',
        resource: 'ADMIN_REPORTS_SERVICE_ACCOUNTING',
        action: 'read',
      },
      {
        title: 'Proforma',
        route: 'ReportProforma',
        resource: 'ADMIN_REPORTS_PROFORMA',
        action: 'read',
      },
      {
        title: 'Müşteri Karnesi',
        route: 'ReportCustomers',
        resource: 'ADMIN_REPORTS_CUSTOMERS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Bayiler',
    route: 'Dealers',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'DealerAdd',
        resource: 'ADMIN_DEALER_ADD',
        action: 'read',
      },
      {
        title: 'Bayi Listesi',
        route: 'Dealers',
        resource: 'ADMIN_DEALER_LIST',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'DealerUserTypes',
        resource: 'ADMIN_DEALER_USER_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ürünler',
    route: 'Products',
    icon: 'PrinterIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'ProductAdd',
        resource: 'ADMIN_PRODUCT_ADD',
        action: 'read',
      },
      {
        title: 'Ürün Listesi',
        route: 'Products',
        resource: 'ADMIN_PRODUCT_LIST',
        action: 'read',
      },
      {
        title: 'Ürün Grupları',
        route: 'ProductGroups',
        resource: 'ADMIN_PRODUCT_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tanımlamalar',
    route: '#',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Markalar',
        route: 'Brands',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Ülke',
        route: 'Countries',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Şehir',
        route: 'Cities',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Temas Tipi',
        route: 'Meets',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Sektör',
        route: 'Sectors',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Faaliyet Alanları',
        route: 'Activities',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'İlgili Kişi Ünvanları',
        route: 'RelatedPersonTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Parkur Tipleri',
        route: 'ParkourTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Makina Tipleri',
        route: 'MachineTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Bankalar',
        route: 'Banks',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Banka Hesapları',
        route: 'BankAccounts',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Para Birimleri',
        route: 'Currencies',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Birim Tipleri',
        route: 'Units',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Müşteri Tipleri',
        route: 'CustomerTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Teklif Özel Şartları',
        route: 'OfferTerms',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Teklif Durumları',
        route: 'OfferStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Görüşme Konuları',
        route: 'InterviewSubjects',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Görüşme Durumları',
        route: 'InterviewStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Vergi Oranları',
        route: 'Taxes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Servis İşlem Tipleri',
        route: 'ServiceTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Servis Durumları',
        route: 'ServiceStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kullanıcılar',
    route: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'ADMIN_USERS',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'UserTypes',
        resource: 'ADMIN_USER_TYPES',
        action: 'read',
      },
      {
        title: 'Müşteri Kullanıcı Tipleri',
        route: 'CustomerUsersTypes',
        resource: 'ADMIN_CUSTOMER_USERS_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ayarlar',
    route: '#',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'SMTP',
        route: 'Smtp',
        resource: 'ADMIN_SMTP_CONFIG',
        action: 'read',
      },
    ],
  },
]
